import React from "react";

import { createSettingsView, ViewComponent } from "@settings/view";

import Display from "./_lib/Display";

const IntegrationsView: ViewComponent = () => {
	return <Display />;
};

export default createSettingsView(IntegrationsView, {
	title: "Integrations",
	internalScroll: true,
	internalPad: true,
});

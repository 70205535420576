import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
	overrideDisplay: {
		display: "flex",
		padding: "5%",
		flexFlow: "column nowrap",
		justifyContent: "center",
		alignItems: "center",
		width: "100%",
		textAlign: "center",
	},
}));

export const DisplayOverride: React.FC = ({ children }) => {
	const styles = useStyles();

	return <Box className={styles.overrideDisplay}>{children}</Box>;
};

import React, { useMemo } from "react";

import Box from "@material-ui/core/Box";
import { Grid } from "@material-ui/core";

import { InfoCard } from "~/components/InfoCard";

import { GlobalLoader } from "@settings/GlobalLoader";
import { AutocorpBlock } from "@settings/AutocorpBlock";
import { CriticalError } from "@settings/CriticalError";

import { useStyles } from "./styles";

import { useAuth } from "@api/auth";
import { useCompanyMeta } from "@api/companies";

import CreditApp from "~/assets/vectors/credit-app.svg";
import { Typography } from "~/components/Typography";

const Display: React.FC = () => {
	const styles = useStyles();
	const { user, selectedCompany } = useAuth();
	const { state, loading, error } = useCompanyMeta();

	const content = useMemo(() => {
		if (user?.autocorpAdmin && !selectedCompany) {
			return <AutocorpBlock />;
		}

		if (error) {
			return (
				<CriticalError
					message={"Unable to retrieve integrations!"}
					error={error}
				/>
			);
		}

		if (loading) {
			return <GlobalLoader />;
		}

		const getCreditAppStatus = (status: string) => {
			if (status?.toLocaleLowerCase() === "pending") {
				return "Pending";
			}
			if (status?.toLocaleLowerCase() === "ready") {
				return "Ready";
			}
			return "Not Integrated";
		};

		const retailerId =
			(state?.lenderTool as any)?.creditApp?.retailerId || "Not Provided";
		const status = getCreditAppStatus(
			(state?.lenderTool as any)?.creditApp?.status!,
		);

		return (
			<Box className={styles.content}>
				<InfoCard title={<CreditApp />}>
					<Grid container>
						<Grid item className={styles.item}>
							<Typography variant="caption">Status:</Typography>
							<Typography>{status}</Typography>
						</Grid>
						<Grid item className={styles.item}>
							<Typography variant="caption">
								Retailer ID:
							</Typography>
							<Typography>{retailerId}</Typography>
						</Grid>
					</Grid>
				</InfoCard>
			</Box>
		);
	}, [loading, selectedCompany, styles, user, error, state]);

	return <Box className={styles.container}>{content}</Box>;
};

export default Display;
